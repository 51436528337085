<template>
  <div class="app-container container-crud-popover">
    <div v-scrollTop>
      <listStateGroup v-model="clientType" :list="list" @change="handleListStateChange"></listStateGroup>
      <SelectAddRootEvn v-if="!isProduction" class="mt20" :otherWindow.sync="otherWindow"></SelectAddRootEvn>
      <avue-crud
        ref="crud"
        class="search-form"
        :option="tableOption"
        :data="finalData[clientType]"
        :tableLoading="tableLoading"
        :search.sync="searchForm"
        lazy
        :load="load"
        @close="handleClose"
        @row-del="rowDel"
        @row-save="rowSave"
        @row-update="rowUpdate"
        @size-change="sizeChange"
        @current-change="pageChange"
        @search-change="searchChange"
        @search-reset="searchChange"
      >
        <template #createTimeSearchForm="{ form, prop }">
          <SaleDateTime :time.sync="form[prop]" @change="searchChange" />
        </template>
        <template #menuLeft>
          <el-button v-if="childPermission.add" type="primary" size="mini" @click="rowAdd()">新增</el-button>
          <addMenu :data="currentTreeMenuList" :clientType="clientType" @submitHandler="submitHandler" />
        </template>
        <template #iconCodeForm="{ form, prop }">
          <el-popover placement="bottom-start" width="460" trigger="click" @show="$refs['iconSelect'].reset()">
            <IconSelect ref="iconSelect" v-model="form[prop]" />
            <el-input
              slot="reference"
              v-model="form[prop]"
              placeholder="点击选择图标"
              readonly
              class="hover-show-parent"
            >
              <svg-icon
                v-if="form[prop]"
                slot="prefix"
                :icon-class="form[prop]"
                class="el-input__icon"
                style="height: 28px; width: 16px"
              />
              <i v-else slot="prefix" class="el-icon-search el-input__icon" />
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close hover-show"
                v-show="form[prop]"
                @click.stop="form[prop] = ''"
              ></i>
            </el-input>
          </el-popover>
        </template>
        <template #menu="{ row, $index }">
          <template v-if="childPermission.addMenu">
            <el-button class="menu-btn-item" type="text" @click="rowCopy(row, $index)">复制</el-button>
            <el-button class="menu-btn-item" type="text" @click="rowCopy(row, $index, true)">批量复制</el-button>
            <el-button class="menu-btn-item" type="text" @click="rowAdd(row)">新增</el-button>
            <addMenu type="single" :row="row" :data="currentTreeMenuList" :clientType="clientType" @submitHandler="submitHandler" />
          </template>
        </template>
      </avue-crud>
    </div>
  </div>
</template>

<script>
import avueCrud from '@/components/base/baseTable/mixins/avueCrud'
import addMenu from './module/addMenu.vue'
import { tableOption, list } from './const'
import SaleDateTime from '@/components/saleDateTime'
import IconSelect from '@/components/IconSelect'
import { list as getList, create, del, update, refreshMenuDic } from '@/api/system/menuManage'
import { deepClone, toDic } from '@/components/avue/utils/util'
import listStateGroup from '@/views/components/listStateGroup'
import { MENU_TYPE_BTN, CURRENT_MENU_TYPE } from '@/utils/constant'
import { checkPermission, getRemark } from '@/utils'
import { validatenull } from '@/components/avue/utils/validate'
import { find, noop } from 'lodash'
import SelectAddRootEvn from '@/views/system/menuManage/module/SelectAddRootEvn'
import selectRootMixin from '@/views/system/menuManage/module/selectRootMixin'

const stage = process.env.VUE_APP_ENV_STAGE
export default {
  mixins: [
    avueCrud({
      tableOption: deepClone(tableOption),
      getList
    }),
    selectRootMixin
  ],
  components: {
    IconSelect,
    SaleDateTime,
    listStateGroup,
    SelectAddRootEvn,
    addMenu
  },
  data() {
    return {
      isBatchCopy: false,
      isCopy: false,
      copyRow: {},
      finalData: {},
      treeLoadMaps: {},
      // 客户端类型 0后台端菜单 1业务端菜单  2工厂端菜单 3财务系统 4物流系统 5MES总后台 6对外MES 7对内MES 8仓储前台 9仓储后台
      clientType: CURRENT_MENU_TYPE
    }
  },
  computed: {
    isProduction() {
      return ['uat', 'prd', 'pro', 'newprd'].includes(stage) || !stage
    },

    list() {
      return list.filter((item) => this.checkPermission(item.permission))
    },
    curItem({ list, clientType }) {
      return list.find(({ value }) => value === clientType)
    },
    childPermission({ curItem: { childPermission } }) {
      let tempObj = {}
      for (const childPermissionKey in childPermission) {
        tempObj[childPermissionKey] = this.checkPermission(childPermission[childPermissionKey])
      }
      return tempObj
    },
    resetMergeData({ clientType }) {
      return {
        clientType
      }
    },
    currentTreeMenuList() {
      return this.$store.getters[this.curItem.name]
    }
  },
  watch: {
    list: {
      handler(n) {
        if (n.length) {
          this.clientType = n[0].value
        }
      },
      immediate: true,
      deep: true
    },
    childPermission: {
      handler(n) {
        this.$set(this.tableOption, 'search', n.search)
        this.$set(this.tableOption, 'editBtn', n.edit)
        this.$set(this.tableOption, 'delBtn', n.delete)
        this.$set(this.tableOption, 'menu', n.addMenu || n.edit || n.delete)
      },
      immediate: true,
      deep: true
    },
    currentTreeMenuList: {
      handler(n, o) {
        if (Array.isArray(n) && n.length && n !== o) {
          this.tableOption.column[0].dicData = n
          this.tableOption.column[12].dicData = n
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    afterInit(res) {
      if ($SUC(res)) {
        let states = $GET(this, '$refs.crud.$refs.table.store.states') || { treeData: {} }
        let expandedIdList = []
        for (const id in states.treeData) {
          if (states.treeData[id].expanded) {
            expandedIdList.push(Number(id))
          }
        }

        let detail = res.detail || []
        let clientType = detail.length ? detail[0].clientType : this.clientType
        this.$set(this.finalData, clientType, [])
        this.$nextTick(function () {
          this.finalData[clientType] = this.handleData(detail)

          // 表格懒加载子节点新增、修改、删除问题解决
          let allData = this.shakingChildren(detail)
          this.$nextTick(function () {
            let { treeLoadMaps } = this
            for (const id in treeLoadMaps) {
              let { tree, treeNode, resolve } = treeLoadMaps[id]
              let row = find(allData, { id: tree.id })
              // console.log(row, id)
              if (validatenull(row?.children)) {
                // 当删除最后一个子元素时，必须清空表格组件里当前层级的数据
                this.$set(states.lazyTreeNodeMap, tree.id, [])
                delete treeLoadMaps[tree.id]
                continue
              }
              if (expandedIdList.includes(row.id)) {
                this.$refs.crud.toggleRowExpansion(row, true)
                this.load(row, treeNode, resolve)
              }
            }
          })
        })
        // this.console(detail)
      }
    },
    handleData(data) {
      return data?.map((row) => {
        row.remark = this.getRemark(row)
        row.hasChildren = !validatenull(row.children)
        this.handleData(row.children)
        return row
      })
    },
    handleListStateChange() {
      this.searchChange()
    },

    load(tree, treeNode, resolve) {
      this.treeLoadMaps[tree.id] = { tree, treeNode, resolve }
      resolve(tree.children || [])
    },
    rowCopy(row, index, isBatchCopy) {
      this.isCopy = true
      this.isBatchCopy = isBatchCopy
      this.copyRow = row
      this.$refs.crud.rowEdit(row, index)
    },
    handleClose() {
      this.isCopy = false
      this.isBatchCopy = false
      this.copyRow = {}
    },
    rowAdd(row) {
      let { crud } = this.$refs
      if (crud) {
        if (row) {
          let sortNumber =
            (validatenull(row.children) ? 0 : Math.max(...(row.children || []).map(({ sortNumber }) => sortNumber))) + 1
          crud.tableForm.parentId = row.id
          crud.tableForm.menuType = MENU_TYPE_BTN
          crud.tableForm.sortNumber = sortNumber
          crud.tableForm.permissionCode = `${row.permissionCode}:${sortNumber}`
        } else {
          let sortNumber =
            (validatenull(this.currentTreeMenuList)
              ? 0
              : Math.max(...(this.currentTreeMenuList || []).map(({ sortNumber }) => sortNumber))) + 1
          crud.tableForm.menuType = MENU_TYPE_BTN
          crud.tableForm.sortNumber = sortNumber
          crud.tableForm.permissionCode = `${this.curItem.prop}:${sortNumber}`
        }
        crud.rowAdd()
        // console.log(crud.tableForm)
      }
    },
    async rowDel(row, index, hide) {
      const query = {
        idList: toDic([row]).map(({ id }) => id),
        clientType: this.clientType
      }
      this.otherWindowSave(query, 'delete', this.currentTreeMenuList)
      let [, res] = await awaitWrap(
        del(query)
      )
      this.isSuccess(res, row)
      hide()
    },
    async rowSave(form, hide, isRefreshDic) {
      form.remark = this.setRemark(form)
      form.clientType = this.clientType
      this.otherWindowSave(form, 'create', this.currentTreeMenuList)
      let [, res] = await awaitWrap(create(form, isRefreshDic))
      typeof hide === 'function' && hide(!!this.isSuccess(res, form))
      return res
    },
    async rowBatchSave(form, hide, changeCode) {
      let p = []
      let res = await this.rowSave(form, null, false)
      if (res?.detail && form.hasChildren) {
        form.children.forEach((item) => {
          item.parentId = res?.detail
          item.permissionCode = changeCode(item.permissionCode)
          p.push(this.rowBatchSave(item, null, changeCode))
        })
      }
      await promiseAll(p)
      typeof hide === 'function' && hide(!!this.isSuccess(res, form))
      return p
    },
    async rowUpdate(form, index, hide) {
      if (this.isBatchCopy || this.isCopy) {
        let { copyRow, currentTreeMenuList } = this
        let nParentNode = this.findNodeById(currentTreeMenuList, form.parentId)
        let oParentNode = this.findNodeById(currentTreeMenuList, copyRow.parentId)
        if (oParentNode && nParentNode && form.permissionCode === copyRow.permissionCode) {
          form.permissionCode = form.permissionCode.replace(oParentNode.permissionCode, nParentNode.permissionCode)
        }

        if (this.isBatchCopy) {
          let res = await this.rowBatchSave(form, hide, (code) =>
            code.replace(copyRow.permissionCode, form.permissionCode)
          )
          refreshMenuDic(this.clientType)
          return res
        } else if (this.isCopy) {
          return this.rowSave(form, hide)
        }
      }

      form.remark = this.setRemark(form)
      form.parentId = form.parentId || 0
      this.otherWindowSave(form, 'update', this.currentTreeMenuList)
      let [, res] = await awaitWrap(update(form))
      hide(!!this.isSuccess(res, form))
    },

    shakingChildren(data, key) {
      let tempArr = []
      data.map((item) => {
        if (Array.isArray(key)) {
          const tmpObj = {}
          key.map((sKey) => {
            tmpObj[sKey] = item[sKey]
          })
          tempArr.push(tmpObj)
        } else if (key) {
          tempArr.push(item[key])
        } else {
          tempArr.push(item)
        }

        if (Array.isArray(item.children) && item.children.length > 0) {
          if (key) {
            tempArr = tempArr.concat(this.shakingChildren(item.children, key))
          } else {
            tempArr = tempArr.concat(this.shakingChildren(item.children))
          }
        }
      })
      return tempArr.filter((item) => item)
    },
    findNodeById(list, id) {
      let node
      list.find(item => {
        if (item.id === id) {
          return node = item
        }
        if (item.children?.length) {
          return node = this.findNodeById(item.children, id)
        }
      })
      return node
    },

    setRemark(row) {
      // console.log(row)
      let childIds = ''
      if (Array.isArray(row.childIds) && row.childIds.length) {
        childIds = `;childIds=${JSON.stringify(row.childIds)}`
      }
      return (row.remark = (row.remark || '') + childIds)
    },
    getRemark,

    checkPermission(permission) {
      if (typeof permission === 'string') return checkPermission(permission)
      return permission
    },

    isSuccess(res, row) {
      if ($SUC(res)) {
        this.$message.success('操作成功')
        this.searchChange()
        return true
      }
    },
    console(data) {
      data.forEach((item) => {
        item.remark && console.log(item.remark)
        !validatenull(item.children) && this.console(item.children)
      })
    },
    
    async submitHandler(data, done) {
      const { json, parentId, sortNumber } = data
      const row = {
        ...json,
        sortNumber,
        parentId
      }
      const index = this.tableData.length + 1
      this.isCopy = true
      this.isBatchCopy = true
      this.copyRow = row
      try {
        await this.rowUpdate(row, index, noop)
        done()
      } catch (error) {
        return done(false)
      }
    }
  }
}
</script>

<style lang="scss"></style>
