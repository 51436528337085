var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container container-crud-popover"},[_c('div',{directives:[{name:"scrollTop",rawName:"v-scrollTop"}]},[_c('listStateGroup',{attrs:{"list":_vm.list},on:{"change":_vm.handleListStateChange},model:{value:(_vm.clientType),callback:function ($$v) {_vm.clientType=$$v},expression:"clientType"}}),(!_vm.isProduction)?_c('SelectAddRootEvn',{staticClass:"mt20",attrs:{"otherWindow":_vm.otherWindow},on:{"update:otherWindow":function($event){_vm.otherWindow=$event},"update:other-window":function($event){_vm.otherWindow=$event}}}):_vm._e(),_c('avue-crud',{ref:"crud",staticClass:"search-form",attrs:{"option":_vm.tableOption,"data":_vm.finalData[_vm.clientType],"tableLoading":_vm.tableLoading,"search":_vm.searchForm,"lazy":"","load":_vm.load},on:{"update:search":function($event){_vm.searchForm=$event},"close":_vm.handleClose,"row-del":_vm.rowDel,"row-save":_vm.rowSave,"row-update":_vm.rowUpdate,"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"search-change":_vm.searchChange,"search-reset":_vm.searchChange},scopedSlots:_vm._u([{key:"createTimeSearchForm",fn:function(ref){
var form = ref.form;
var prop = ref.prop;
return [_c('SaleDateTime',{attrs:{"time":form[prop]},on:{"update:time":function($event){return _vm.$set(form, prop, $event)},"change":_vm.searchChange}})]}},{key:"menuLeft",fn:function(){return [(_vm.childPermission.add)?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.rowAdd()}}},[_vm._v("新增")]):_vm._e(),_c('addMenu',{attrs:{"data":_vm.currentTreeMenuList,"clientType":_vm.clientType},on:{"submitHandler":_vm.submitHandler}})]},proxy:true},{key:"iconCodeForm",fn:function(ref){
var form = ref.form;
var prop = ref.prop;
return [_c('el-popover',{attrs:{"placement":"bottom-start","width":"460","trigger":"click"},on:{"show":function($event){return _vm.$refs['iconSelect'].reset()}}},[_c('IconSelect',{ref:"iconSelect",model:{value:(form[prop]),callback:function ($$v) {_vm.$set(form, prop, $$v)},expression:"form[prop]"}}),_c('el-input',{staticClass:"hover-show-parent",attrs:{"slot":"reference","placeholder":"点击选择图标","readonly":""},slot:"reference",model:{value:(form[prop]),callback:function ($$v) {_vm.$set(form, prop, $$v)},expression:"form[prop]"}},[(form[prop])?_c('svg-icon',{staticClass:"el-input__icon",staticStyle:{"height":"28px","width":"16px"},attrs:{"slot":"prefix","icon-class":form[prop]},slot:"prefix"}):_c('i',{staticClass:"el-icon-search el-input__icon",attrs:{"slot":"prefix"},slot:"prefix"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(form[prop]),expression:"form[prop]"}],staticClass:"el-input__icon el-icon-circle-close hover-show",attrs:{"slot":"suffix"},on:{"click":function($event){$event.stopPropagation();form[prop] = ''}},slot:"suffix"})],1)],1)]}},{key:"menu",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(_vm.childPermission.addMenu)?[_c('el-button',{staticClass:"menu-btn-item",attrs:{"type":"text"},on:{"click":function($event){return _vm.rowCopy(row, $index)}}},[_vm._v("复制")]),_c('el-button',{staticClass:"menu-btn-item",attrs:{"type":"text"},on:{"click":function($event){return _vm.rowCopy(row, $index, true)}}},[_vm._v("批量复制")]),_c('el-button',{staticClass:"menu-btn-item",attrs:{"type":"text"},on:{"click":function($event){return _vm.rowAdd(row)}}},[_vm._v("新增")]),_c('addMenu',{attrs:{"type":"single","row":row,"data":_vm.currentTreeMenuList,"clientType":_vm.clientType},on:{"submitHandler":_vm.submitHandler}})]:_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }