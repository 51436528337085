/* eslint-disable */
import request from '@/service/request'
import { MENU_DIC, REQUEST_ALL_DATA, MENU_DIC_NAMES, getXMenuType } from '@/utils/constant'
import store from '@/store'

// 获取所有的菜单树 默认查询所有
export function list(data = REQUEST_ALL_DATA, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/menu/treeMenuList',
    method: 'post',
    data
  })
}

// 创建
export function create(data, isRefreshDic = true) {
  let promise = request({
    url: '/externaladmin/authService/menu/menuCreate',
    method: 'post',
    data
  })
  return isRefreshDic
    ? refreshDic(
      promise,
      MENU_DIC[data.clientType].name
    )
    : promise
}

export function refreshMenuDic(clientType) {
  store.dispatch('RefreshDic', MENU_DIC[clientType].name)
}

// 批量创建
export function batchCreate(data) {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      url: '/auth/menu/batchCreate',
      method: 'post',
      data
    }),
    MENU_DIC_NAMES[data.clientType]
  )
}

// 删除
export function del(data, menuType = 'btn') {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: 'externaladmin/authService/menu/delMenu',
      method: 'post',
      data
    }),
    MENU_DIC_NAMES[data.clientType]
  )
}

// 更新
export function update(data, menuType = 'btn') {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/authService/menu/menuUpdate',
      method: 'post',
      data
    }),
    MENU_DIC_NAMES[data.clientType]
  )
}

// 更新
export function batchUpdate(data) {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      url: '/auth/menu/batchUpdate',
      method: 'post',
      data
    }),
    MENU_DIC_NAMES[data.clientType]
  )
}
