/* eslint-disable */
import {create, del, update} from '@/api/system/menuManage'
import {isPlainObject} from 'lodash'
import {deepClone} from '@/components/avue/utils/util'


export default {
  data() {
    return {
      otherWindow: []
    }
  },
  created() {
    if (this.isProduction || !opener) return

    window.addEventListener('message', this.rootHandle, false)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('message', this.rootHandle)
    })
  },

  methods: {
    otherWindowSave(data, type, menuList) {
      if (this.isProduction) return

      this.otherWindow.map(item => {
        item.window.postMessage({
          event: 'otherWindowSave',
          type,
          data,
          menuList
        }, item.url)
      })
    },
    rootHandle(evt) {
      // console.log('evt', evt)
      const {data} = evt
      if (data.type === 'create') {
        this.createRoot(evt)
      }
      if (data.type === 'delete') {
        this.deleteRoot(evt)
      }
      if (data.type === 'update') {
        this.updateRoot(evt)
      }
      if(data.event === 'notice') {
        if(data.type === 'success') {
          this.$message.success(`${evt.origin}${data.message}成功`)
        } else {
          this.$message.error(`${evt.origin}${data.message}失败`)
        }
      }
    },
    async createRoot(evt) {

      const {data} = evt
      if (isPlainObject(data)) {
        if (data.event === 'otherWindowSave') {
          const openerForm = data.data
          const openerMenuList = data.menuList
          const selfForm = this.contrastCreateHandle(openerForm,openerMenuList)
          let [, res] = await awaitWrap(create(selfForm))
          if(this.isSuccess(res)) {
            this.notice('success', '创建')
          } else {
            this.notice('error', '创建')
          }
        }
      }
    },
    async updateRoot(evt) {
      const {data} = evt
      if (isPlainObject(data)) {
        if (data.event === 'otherWindowSave') {
          const openerForm = data.data
          const openerMenuList = data.menuList
          const selfForm = this.contrastUpdateHandle(openerForm,openerMenuList)
          let [, res] = await awaitWrap(update(selfForm))
          if(this.isSuccess(res)) {
            this.notice('success', '更新')
          } else {
            this.notice('error', '更新')
          }
        }
      }
    },
    async deleteRoot(evt) {
      const {data} = evt
      if (isPlainObject(data)) {
        if (data.event === 'otherWindowSave') {
          const openerForm = data.data
          const openerMenuList = data.menuList
          const selfForm = this.contrastDeleteHandle(openerForm, openerMenuList)
          let [, res] = await awaitWrap(del(selfForm))
          if(this.isSuccess(res)) {
            this.notice('success', '删除')
          } else {
            this.notice('error', '删除')
          }
        }
      }
    },
    contrastCreateHandle(openerForm, openerMenuList, selfMenuList = this.currentTreeMenuList) {
      const selfForm = deepClone(openerForm)
      if (selfForm.parentId) {
        const openerParentId = openerForm.parentId
        const openerParentNode = this.findNodeById(openerMenuList, openerParentId)
        const selfParentNode = this.findNodeByCode(selfMenuList, openerParentNode.permissionCode)
        selfForm.parentId = selfParentNode.id
      }
      console.log('selfForm', selfForm)
      return selfForm
    },
    contrastUpdateHandle(openerForm, openerMenuList, selfMenuList = this.currentTreeMenuList) {
      const selfForm = deepClone(openerForm)
      const openerId = openerForm.id
      const openerNode = this.findNodeById(openerMenuList, openerId)
      const selfNode = this.findNodeByCode(selfMenuList, openerNode.permissionCode)
      selfForm.id = selfNode.id
      if(selfForm.parentId) {
        const openerParentId = openerForm.parentId
        const openerParentNode = this.findNodeById(openerMenuList, openerParentId)
        const selfParentNode = this.findNodeByCode(selfMenuList, openerParentNode.permissionCode)
        selfForm.parentId = selfParentNode.id
      }
      return selfForm
    },
    contrastDeleteHandle(openerForm, openerMenuList, selfMenuList = this.currentTreeMenuList) {
      const selfForm = deepClone(openerForm)
      const openerRootId = openerForm.idList[0]
      const openerNode = this.findNodeById(openerMenuList, openerRootId)
      const selfNode = this.findNodeByCode(selfMenuList, openerNode.permissionCode)
      const idList = this.findIdList([selfNode])
      selfForm.idList = idList
      return selfForm
    },
    findNodeById(menuList, id) {
      let node
      menuList.find(currentObj => {
        if (currentObj.id === id) {
          return node = currentObj
        }
        if (currentObj.children?.length) {
          return node = this.findNodeById(currentObj.children, id)
        }
      })
      return node
    },
    findNodeByCode(selfMenuList, permissionCode) {
      let node
      selfMenuList.find(currentObj => {
        if (currentObj.permissionCode === permissionCode) {
          return node = currentObj
        }
        if (currentObj.children?.length) {
          return node = this.findNodeByCode(currentObj.children, permissionCode)
        }
      })
      return node
    },
    findIdList(selfNodeArr, newArr = []) {
      for(let val of selfNodeArr) {
        newArr = [...newArr, val.id]
        if (val.children?.length > 0) {
          newArr = this.findIdList(val.children, newArr)
        }
      }
      return newArr
    },
    notice(type, message) {
      window.opener.postMessage({
        event: 'notice',
        type,
        message
      }, '*')
    }
  }
}
