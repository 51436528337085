<template>
  <div class="SelectAddRootEvn">
    <el-select v-model="value" size="mini" multiple placeholder="请选择额外添加权限的环境">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item">
      </el-option>
    </el-select>
    <el-button size="mini" @click="goOtherPage">打开其他环境页面</el-button>
  </div>
</template>

<script>
export default {
  props: {
    otherWindow: {
      type: Array,
      default: () => []
    }
  },
  components: { },
  data () {
    return {
      value: [],
      newOtherWindow: [],
      options: [
        {
          label: 'local',
          value: 'http://192.168.10.190:8401/#/system/menuManage'
        },
        {
          label: 'sit',
          value: 'http://192.168.10.208:8201/#/system/menuManage'
        },
        {
          label: 'test',
          value: 'http://192.168.10.196:8201/#/system/menuManage'
        },
        {
          label: 'uat',
          value: 'http://192.168.10.192:8201/#/system/menuManage'
        }
      ]
    }
  },
  computed: { },
  methods: {
    goOtherPage() {
      this.newOtherWindow = []
      this.value.map(item => {
        const obj = window.open(item.value)
        console.log(obj)
        this.newOtherWindow.push({
          window: obj,
          url: item.value
        })
      })
      this.$emit('update:otherWindow', this.newOtherWindow)
      console.log('this.otherWindow', this.otherWindow)
    }
  }
}
</script>

<style lang="scss" scoped>
.SelectAddRootEvn {
  margin-top: 20px;
  margin-bottom: 20px;
  .el-select {
    width: 200px;
    margin-right: 20px;
  }
}
</style>
